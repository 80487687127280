<template>
  <v-card
    class="mb-4"
    :disabled="disabled">
    <v-card-title>
      <v-icon class="handle">mdi-arrow-all</v-icon>
      <span>{{ item.name }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="remove(item, idx)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="item.title"
                label="Заголовок"
                @blur="update(item)"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <vue-editor
                ref="editor"
                v-model="item.value"
                :editor-toolbar="customToolbar"
                @blur="update(item)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'TextCard',
  props: ['item', 'idx', 'disabled'],
  components: {
    VueEditor,
  },
  data() {
    return {
      valid: false,
      formTitle: '',
      formValue: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image'],
        ['link'],
        ['clean'],
      ],
    };
  },
  methods: {
    remove(item, idx) {
      this.$emit('remove', { item, idx });
    },
    update(item) {
      this.$emit('update', { item });
    },
  },
};
</script>

<style scoped>

</style>
