<template>
  <v-container fluid>
    <v-row>
      <v-tabs  v-model="contentType" height="72" slider-color="#333333">
        <v-tab v-show="isGeneralInfo">Общая информация</v-tab>
        <v-tab v-show="!isGeneralInfo">Информация о продуктах</v-tab>
        <v-tab v-show="!isGeneralInfo">Информация о товарах</v-tab>
      </v-tabs>
    </v-row>
    <v-row>
      <div
        class="d-flex flex flex-column left-side">
        <draggable
          class="d-flex pa-4"
          :list="template"
          :group="{ name: 'people', pull: 'clone', put: false }"
          style="background-color: white;"
          :sort="false"
          :clone="cloneElement"
          @change="log"
        >
          <div
            class="element pa-4 mr-2"
            v-for="element in template"
            :style="editBlocked && 'cursor: not-allowed'"
            :key="element.type"
          >
            <v-icon color="white">{{element.icon}}</v-icon>
            {{ element.name }}
          </div>
        </draggable>
        <div class="flex overflow-auto" style="position: relative">
          <div
            v-if="computedContent.length === 0"
            class="empty-drag-area"
            style="position: absolute">
            <span class="valign">Перенесите сюда элемент</span>
          </div>
          <div>
            <draggable
              :list="content"
              group="people"
              @change="log"
              handle=".handle"
              :disabled="editBlocked"
            >
              <div
                v-for="(item, idx) in computedContent"
                :key="`${item.index}${idx}`"
                class="pa-4"
              >
                <text-card
                  v-if="item.type === 1"
                  ref="textCard"
                  :idx="idx"
                  :item="item"
                  :disabled="editBlocked"
                  @remove="removeElement(item, idx)"
                  @update="updateElement(item)"
                ></text-card>
                <image-card
                  v-else-if="item.type === 2"
                  :idx="idx"
                  :item="item"
                  :disabled="editBlocked"
                  @onImageSelect="handleImage"
                  @remove="removeElement(item, idx)"
                ></image-card>
                <accordion-card
                  v-else-if="item.type === 3"
                  :idx="idx"
                  :item="item"
                  :disabled="editBlocked"
                  @remove="removeElement(item, idx)"
                  @update="updateElement(item)">
                </accordion-card>
                <template v-else>
                  <v-card class="mb-4" :disabled="editBlocked">
                    <v-card-title>
                      <h6>Неподдерживаемый элемент</h6>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="removeElement(item, idx)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                  </v-card>
                </template>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <!--  PREVIEW    -->
      <div class="right-side">
        <div style="position:relative; width: 100%">
          <v-row class="ma-0 pa-0"
                 style="position: sticky; top: 0; background-color: white"
          >
            <v-col cols="6" align="center">
              <v-btn @click="goBack">
                <v-icon dark left>
                  mdi-close-circle
                </v-icon>
                ЗАКРЫТЬ
              </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn
                color="primary"
                @click="publishSection"
                :disabled="editBlocked || !computedContent.length"
              >
                <v-icon dark left>
                  mdi-checkbox-marked-circle
                </v-icon>
                ЗАВЕРШИТЬ
              </v-btn>
            </v-col>
          </v-row>
          <div class="mockup-bg mockup-shadow">
            <div class="pa-2 mb-2">
              <h6>
                {{ section ? section.sectionName : '' }}
              </h6>
            </div>
            <div
              v-for="(previewItem, idx) in computedContent"
              :key="`${previewItem.index}${idx}`"
            >
              <!--      'TEXT'        -->
              <template v-if="previewItem.type === 1">
                <v-card class="mb-2">
                  <v-card-title style="font-size: 10px; font-weight: bold">
                    {{ previewItem.title }}
                  </v-card-title>
                  <v-card-subtitle style="font-size: 8px;">
                    <div v-html="previewItem.value"></div>
                  </v-card-subtitle>
                </v-card>
              </template>
              <!--      'IMAGE'        -->
              <template v-else-if="previewItem.type === 2">
                <v-img
                  v-if="previewItem.value"
                  :src="getImageURL(previewItem)"
                  class="mb-2"
                />
              </template>
              <!--      'ACCORDION'        -->
              <template v-else-if="previewItem.type === 3">
                <v-expansion-panels accordion class="mb-2">
                  <v-expansion-panel>
                    <v-expansion-panel-header style="padding: 12px; min-height: 48px;">
                      <span style="font-size: 10px" class="font-weight-bold">
                        {{ previewItem.title }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="font-size: 8px">
                      <div v-html="previewItem.value"></div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <template v-else>
                <v-card class="mb-4">
                  <v-card-text>
                    Неподдерживаемый элемент
                  </v-card-text>
                </v-card>
              </template>
            </div>
          </div>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import getEnv from '@/utils/env';
import TextCard from './components/TextCard.vue';
import ImageCard from './components/ImageCard.vue';
import AccordionCard from './components/AccordionCard.vue';

let elementId = 0;
export default {
  name: 'ManageSection',
  components: {
    TextCard,
    ImageCard,
    AccordionCard,
    draggable,
  },
  created() {
    if (this.$route.params.id) {
      this.sectionId = this.$route.params.id;
      this.getSectionContent(this.sectionId);
    }
  },
  data() {
    return {
      sectionId: null,
      section: null,
      editBlocked: true,
      template: [
        {
          type: 1,
          typeName: 'TEXT',
          name: 'Текстовое поле',
          icon: 'mdi-text-box-plus',
        },
        {
          type: 2,
          typeName: 'IMAGE',
          name: 'Картинка',
          icon: 'mdi-image-plus',
        },
        {
          type: 3,
          typeName: 'ACCORDION',
          name: 'Аккордеон',
          icon: 'mdi-playlist-plus',
        },
      ],
      content: [],
      contentType: 0,
      type: null,
    };
  },
  computed: {
    computedContent() {
      const arr = [...this.content]
      return arr.filter(i => i.content === this.contentType + 1)
    },
    isGeneralInfo() {
      return this.type === 1
    }
  },
  methods: {
    getSectionContent(id) {
      this.$loading(true);
      this.$wikiService.get(`/Section/${id}`).then((response) => {
        const { type, infos, ...etc } = response.content;
        this.type = type
        this.section = { ...etc };
        this.editBlocked = etc.state === 3;
        this.content = response.content.infos.map((item) => ({
          ...item,
          // eslint-disable-next-line no-nested-ternary
          typeName: item.type === 1 ? 'TEXT' : item.type === 2 ? 'IMAGE' : 'ACCORDION',
        }));
        if (this.type === 2) {
          const contentIds = this.content.map(i => i.content)
          if (contentIds.includes(2) && !contentIds.includes(3)) {
            this.contentType = 1
          } else if (!contentIds.includes(2) && contentIds.includes(3)) {
            this.contentType = 2
          } else {
            this.contentType = 1
          }
        }
      }).finally(() => {
        this.$loading(false);
      });
    },
    addElement(element, newIndex) {
      const body = {
        sectionId: this.sectionId,
        ...element,
        index: newIndex + 1,
        content: this.contentType + 1
      };
      this.$wikiService.post('/Info/add', body).then((response) => {
        this.content.splice(newIndex, 0, {...element, id: response.content, content: this.contentType + 1})
      });
    },
    updateElement(element) {
      const body = {
        sectionId: this.sectionId,
        ...element,
      };
      this.$wikiService.put('/Info/update', body).then(() => {
      });
    },
    async changeIndex(id, index) {
      await this.$wikiService.put(`/Info/changeIndex?Id=${id}&Index=${index + 1}`)
      this.computedContent
    },
    removeElement(element, idx) {
      this.$wikiService.delete(`/Info/delete?id=${element.id}`)
        .then(() => {
          this.content.splice(idx, 1);
        });
    },
    async urlToFile({ url, file }) {
      const res = await fetch(url);
      const blob = await res.blob();
      return new File([blob], file.name, { type: file.type });
    },
    async handleImage({ imageItem, ...image }) {
      const findIndex = this.content.findIndex((item) => item.index === imageItem.index);
      if (findIndex > -1) {
        this.$set(this.content, findIndex, {
          ...imageItem,
          loading: true,
        });
      }
      const bodyFormData = new FormData();
      const base64ToFile = await this.urlToFile(image);
      bodyFormData.append('file', base64ToFile);
      const res = await this.$image.post(
        '/image/api/image',
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      delete this.content[findIndex].loading;
      if (findIndex > -1) {
        this.$set(this.content, findIndex, {
          ...imageItem,
          value: res.data.path,
        });
        this.updateElement(this.content[findIndex]);
      }
    },
    getImageURL(item) {
      return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${item.value}`;
    },
    cloneElement({ type, typeName, name }) {
      elementId += 1;
      return {
        type,
        typeName,
        name,
        index: elementId,
        title: '',
        value: '',
      };
    },
    /** Переводит в статус "Завершен" */
    publishSection() {
      this.$loading(true);
      this.$wikiService.put(`/Section/publish?id=${this.sectionId}&state=1`)
        .then(() => {
          this.$store.dispatch('tools/setSnackbar', {
            type: 'success',
            message: 'Изменения сохранены',
          });
          this.goBack();
        })
        .catch(error => {
          const message = error.response?.data?.Message
          this.$store.dispatch('tools/setSnackbar', {
            type: 'error',
            message: message || 'Произошла ошибка',
          });
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    log(evt) {
      // console.log(evt);
      switch (Object.keys(evt)[0]) {
        case 'added':
          // evt.added.newIndex,
          this.addElement(evt.added.element, evt.added.newIndex);
          break;
        case 'moved':
          // evt.moved.oldIndex,
          this.changeIndex(evt.moved.element.id, evt.moved.newIndex);
          break;
        case 'removed':
          // this.removeElement(evt.removed.oldIndex, evt.removed.element);
          // console.log('response', evt.removed.oldIndex, evt.removed.element);
          break;
        default: break;
      }
    },
    goBack() {
      /** Обновление текстовых блокоов происходит на onBlur. Проверяем рефы и вызываем blur */
      try {
        if (this.$refs.textCard) {
          this.$refs.textCard.forEach((el) => {
            if (el.$refs.editor) {
              el.$refs.editor.quill.blur();
            }
          });
        }
      } catch (e) {
        console.log('e', e);
      }
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.mockup-bg {
  background-color: white;
  width: 75%;
  margin: 16px auto;
  padding:  32px 12px;
  border: 12px solid black;
  border-radius: 50px;
  overflow: auto;
  height: 70vh;
  max-height: 584px;
}
.mockup-shadow {
  -webkit-box-shadow: 0 5px 8px 2px rgba(0,0,0,0.55);
  box-shadow: 0 5px 8px 2px rgba(0,0,0,0.55);
}
.handle {
  cursor: move;
}
.left-side {
  background-color: #cccccc;
  height: 75vh;
  overflow: auto;
  flex: 1;
}
.right-side {
  width: 380px;
  height: 75vh;
  overflow: auto;
  border: 1px solid black;
  position: relative;
}
.element {
  background-color: #2d4d76;
  color: white;
  border: 1px dashed white;
  border-radius: 16px;
  cursor: move;
}
.json-caption {
  max-width: 400px;
  line-height: 1.8;
  word-wrap: break-word;
  display: inline-block;
}
.empty-drag-area {
  width: 100%;
  height: 50%;
  text-align: center;
  border: 1px dashed black;
}
.valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
