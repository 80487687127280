<template>
  <v-card
    :loading="item.loading"
    :disabled="disabled"
    class="mb-4"
  >
    <v-card-title>
      <v-icon class="handle">mdi-arrow-all</v-icon>
      <span>{{ item.name }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="remove(item, idx)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="text-center">
      <img
        v-if="item.value"
        :src="getImageURL(item.value)"
      />
      <ImagePreviewUploader
        v-else
        :imageItem="item"
        :imageURL="item.value"
        :loading="item.loading"
        @onImageSelect="onImageSelect"
      />
    </v-card-text>
  </v-card>
</template>

<script>

import getEnv from '@/utils/env';

export default {
  name: 'ImageCard',
  props: ['item', 'idx', 'disabled'],
  data() {
    return {
      valid: false,
      formTitle: '',
      formValue: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 10 || 'Name must be less than 10 characters',
      ],
    };
  },
  methods: {
    onImageSelect(image) {
      this.$emit('onImageSelect', image);
    },
    getImageURL(path) {
      return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${path}`;
    },
    remove(item, idx) {
      this.$emit('remove', { item, idx });
    },
  },
};
</script>

<style scoped>
img {
  width: 300px;
  height: auto;
}
</style>
